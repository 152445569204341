.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:regular,bold,italic&subset=latin,latin-ext');

div {
  font-family: 'Source Sans Pro';
}

p {
  font-family: 'Source Sans Pro';
}

.navbar-dark .navbar-toggler-icon {
  background-image: url('../src/sources/images/hamburger.png')!important;
  width: 32px;
  height: 32px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-layout {
  /* margin-right: 20%;
  margin-left: 20%; */
}

.logo {
  width: 125px;
  height: 125px;
  margin: 0px 20px;
}

.logo-text {
  color: #000;
  font-size: 30px;
  line-height: 1.4;
  letter-spacing: 0px;
  margin: 0px 20px;
  font-family: 'Georgia',Times,serif;
}

.brand-container {
  display: flex;
  align-items: center;
}

.nav-container {
  justify-content: end;
  margin-left: 20px;
  margin-right: 20px;
}

.ebLogo {
  height: 40px;
  margin: 20px 10px;
}

.ebLogo-container {
  color: #000;
}

.ebLogo-container:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.custom-list-container {
  line-height: 15px;
}

.diffcard-footer-ebLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.eb-link-container {
  margin-left: 40px;
  text-align: center;
}

.eb-link {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
}

.eb-link:hover {
  color: #12426a;
  text-decoration: none;
  border-bottom: 2px solid #12426a;
  padding-bottom: 5px;
}

.page-header-img-container {
  position: relative;
}

.page-header-img {
  object-fit: cover;
  width: 100%;
  height: 600px;
}

.tint {
  filter: brightness(60%);
}

.page-header-text-container {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #000;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.page-header-text-sub-container {
  padding: 20px;
  color: #FFF;
}

.page-header-text {
  font-size: 60px;
  line-height: 92px;
  font-family: "Georgia", Times, serif;
  width: 57%;
}

.page-header-sub-text {
  font-size: 40px;
  line-height: 56px;
  width: 57%;
}

.page-header-btn-container {
  padding: 20px 0;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.page-header-btn {
  background-clip: border-box;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 3px solid #12426a;
  padding: 5px 15px;
  background-color: #12426a;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.page-header-btn:hover {
  background-color: #FFF;
  color: #12426a;
  text-decoration: none;
}

.submission-btn-container {
  display: flex;
  justify-content: space-evenly;
}

.mobile-show {
  display: none;
}

.custom-navbar {
  background-color: #FFF;
  border-bottom: 1px dotted #000;
}

.ebBtnContainer {
  padding-left: 16px;
}

.ebBtn {
  background-color: #fed440;
  color: #000;
  border: 1px solid;
  height: 48px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 18px;
  font-family: 'Source Code Pro';
}

.ebBtn2 {
  background-color: #000;
  color: #fff;
  min-height: 68px;
  padding-top: 21px;
  padding-bottom: 21px;
  padding-right: 42px;
  padding-left: 42px;
  text-decoration-line: underline;
  font-size: 18px;
  font-family: 'Source Code Pro';
}

.ebBtn:hover {
  color: #000;
}

.ebBtn2:hover {
  color: #fed440;
  cursor: pointer;
}

.ebLinkContainer {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
  padding-left: 16px;
}

.ebLink {
  color: #fff;
  font-size: 18px;
  font-family: 'Source Code Pro';
}

.ebLink:hover {
  color: #fed440;
  cursor: pointer;
  text-decoration-line: underline;
}

.ebLinkBackBtn {
  color: #fff;
  font-size: 18px;
  border-bottom: 1px solid white;
  vertical-align: sub;
  cursor: pointer;
}

.additional-file-main, .additional-file-one, .additional-file-two {
  border: 1px dashed #cccccc !important;
  background: rgba(0, 0, 0, 0.02);
  padding: 50px 25px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 25px;
}

.subNav {
  padding: 5px;
  background-color: #fed440;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.subNav.solutions.arrow-top:after {
  content: " ";
  position: absolute;
  right: 375px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #fed440;
}

.subNav.resources.arrow-top:after {
  content: " ";
  position: absolute;
  left: 290px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #303030;
}

.alignRow {
  text-align: center;
}

.subNavItemContainer {
  margin: 10px;
}

.sub-nav-item {
  color: #000;
  font-size: 18px;
  padding: 21px;
  font-family: 'Source Code Pro';
}

.sub-nav-item:hover {
  color: #000;
  font-size: 18px;
  text-decoration-line: underline;
}

.verticalAlignItem {
  display: inline-grid;
  height: 100%;
  align-items: center;
}

.horizontalAlignItem {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
}

.twocolordiv  {
  position: relative;
  z-index: 9;
  background: white;
  padding: 75px;
}

.twocolordiv:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 50%;
  left: 0;
  background: #ebebeb;
}

.two-color-card-container {
  padding-left: 5%;
  padding-right: 5%;
}

.two-color-card-text-container {
  padding-bottom: 35px;
}

.two-color-card-text {
  font-size: 3.4375em;
}

.two-color-card-subtitle {
  text-align: center;
  font-size: 3.4375em;
}

.two-color-card-subtext {
  text-align: center;
  font-size: 1.625em;
  padding-right: 50px;
  padding-left: 50px;
}

.two-color-card-sub-container {
  padding: 75px;
}

.table-container {
  padding: 75px;
}

.table-header-container {
  padding-left: 5%;
  padding-right: 5%;
}

.table-title {
  font-size: 3.4375em;
}

.table-text {
  color: #000;
}

.table-text-two {
  color: #FFF;
}

.table-text:hover {
  color: #0000FF;
}

.table-text-two:hover {
  color: #0000FF;
}

.table-sub-container {
  padding-left: 75px;
  padding-right: 75px;
}

.table-subtext {
  font-size: 1.625em;
  padding-right: 50px;
  padding-left: 50px;
}

.table-content {
  display: flex;
  padding: 184px;
  padding-bottom: 10px;
  padding-top: 30px;
}

.table-content-container {
  padding-top: 1px;
  padding-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
}

.table-column {
  padding-left: 25px;
}

.table-column-container {
  display: flex;
}

.table-content-subtext {
  padding-bottom: 20px;
  font-size: 1.3125em;
}

.paragraph-container {
  text-align: center;
  padding: 75px;
}

.paragraph-header-container {
  padding-left: 5%;
  padding-right: 5%;
}

.basic-page-container {
  padding-left: 396px;
  padding-right: 396px;
  padding-bottom: 110px;
}

.basic-page-title-main {
  font-size: 3.4375em;
}

.basic-page-title-container {
  padding-top: 110px;
  padding-bottom: 55px;
}

.basic-page-subtitle-container {
  padding-bottom: 26px;
  font-size: 1.3125em;
}

.basic-page-content-container {
  font-size: 1.3125em;
}

.basic-page-content-block {
  padding-bottom: 20px;
}

.basic-page-content-block-title {
  font-size: 1.625em;
}

.basic-page-content-block-subtitle {
  padding-bottom: 5px;
  font-size: 1.3125em;
}

.basic-page-content-block-text {
  font-size: 21px;
}

.paragraph-title-main {
  font-size: 3.4375em;
}

.paragraph-title {
  font-size: 3.4375em;
}

.paragraph-sub-container {
  padding-right: 75px;
  padding-left: 75px;
}

.paragraph-subtitle {
  font-size: 1.625em;
}

.paragraph-subtext {
  font-size: 1.625em;
  padding-right: 50px;
  padding-left: 50px;
}

.modal-text {
  font-size: 1.3125em;
}

.horizontal-diff-card-container {
  text-align: center;
  padding: 75px;
  padding-top: 25px;
}

.horizontal-diff-container {
  display: flex;
  text-align: center;
}

.horizontal-diff-sub-container {
  width: 50%;
}

.horizontal-diff-img {
  width: 150px;
  height: 150px;
}

.footer-text {
  color: #FFF;
  text-decoration-line: underline;
}

.footer-text:hover {
  color: #fed440;
}

.form-container {
  width: 625px;
}

.form-input {
  transform: scale(1.5);
}

.field-container {
  width: 100%;
  display: inline-flex;
}

.field-box-container {
  width: 50%;
}

.field-label {
  font-size: 1.3125em;
}

.homeimg {
  width: 100%;
  height: 900px;
  object-fit: cover;
}

.home-img-text {
  font-size: 68px;
  line-height: normal;
  padding-bottom: 25px;
}

.home-img-text-sub {
  font-size: 34px;
  line-height: normal;
}

.home-img-text-container {
  padding: 42px;
}

.text-block {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #fff;
  color: #000;
  padding-left: 20px;
  padding-right: 20px;
  width: 50%;
  height: 355px;
}

.about-us-title-container {
  padding: 75px;
  text-align: center;
}

.about-us-table {
  padding: 75px;
}

.about-us-table-container {
  padding-left: 396px;
  padding-right: 396px;
}

.card-container {
  margin: 70px 0;
}

.card-padder {
  padding: 20px;
}

.pipe {
  margin-left: 5px;
  margin-right: 5px;
}

.diffcard-footer-container {
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #12426a;
}

.diffcard-footer-link-main-container {
  margin: 20px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.75);
  padding: 20px;
}

.diffcard-footer-link-container {
  display: flex;
  color: #FFF;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.diffcard-footer-link-sub-container {
  padding: 5px 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.75);
}

.diffcard-footer-link-sub-container a {
  color: #FFF;
  text-decoration: none;
}

.diffcard-footer-link-sub-container a:hover {
  color: #FFF;
  text-decoration: none;
}

.diffcard-footer-link-first {
  border-left: 0px solid;
}

.diffcard-footer-brand-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.diffcard-statement-container {
  padding: 70px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #12426a;
}

.diffcard-statement-sub-container {
  width: 60%;
}

.diffcard-statement-content {
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: 0px;
  font-family: "Georgia",Times,serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-align: center;
  color: #FFF;
}

.diffcard-statement-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.diffcard-text-underline {
  text-decoration: underline;
}

.diffcard-contact-content-title {
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: 0px;
  font-family: "Georgia",Times,serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-align: center;
  padding: 50px 20px;
}

.diffcard-brand-container {
  color: #FFF;
  border: 3px solid #FFF;
  padding: 5px 10px;
}

.diffcard-brand-text {
  color: #FFF;
  font-size: 30px;
  font-weight: 700;
}

.diffcard-brand-text:hover {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

.diffcard-icon-container {
  display: flex;
  align-items: center;
  color: #FFF;
  font-size: 20px;
  padding-bottom: 15px;
}

.diffcard-icon-container-secondary {
  color: #12426a;
  font-size: 30px;
}

.diffcard-icon-text {
  color: #FFF;
  padding-left: 20px;
  font-size: 18px;
}

.diffcard-icon-text-clickable {
  color: #FFF;
  font-size: 18px;
}

.diffcard-icon-text-clickable:hover {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

.diffcard-icon-text-secondary {
  color: #444444;
  font-size: 18px;
}

.diffcard-icon-text-secondary:hover {
  color: #000;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
}

.diffcard-social-container {
  font-size: 24px;
  display: flex;
  align-items: center;
}

.diffcard-social-sub-container {
  padding: 0px 5px;
  margin: 0px 5px;
  border: 1px solid;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #FFF;
}

.diffcard-social-sub-content {
  color: #12426a;
  width: inherit;
  height: inherit;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.diffcard-social-sub-content:hover {
  color: #12426a;
}

.diffcard-header-container {
  padding: 70px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(at center center, #12426a 43%, #000000 160%);
}

.diffcard-header-sub-container {
  border: 2px solid #FFF;
  width: 958px;
}

.diffcard-header-content {
  margin: 20px;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: 0px;
  font-family: "Georgia",Times,serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-align: center;
  color: #FFF;
}

.diffcard-element {
  padding-right: 40px;
  padding-left: 40px;
  margin: 20px 0;
}

.diffcard-element-container {
  text-align: center;
  line-height: 28px;
  color: #000;
}

.diffcard-action-container {
  background-color: #F9F9F9;
  border-top: 5px solid #12426a;
  padding: 20px;
  width: 525px;
  text-align: center;
}

.diffcard-submission-container {
  padding: 70px 20px;
}

.diffcard-submission-form-container {
  background-color: #FFF;
  border-top: 5px solid #12426a;
  padding: 20px;
  width: 765px;
}

.diffcard-submission-form-sub-container {
  margin: 20px;
  font-size: 18px;
}

.diffcard-qa-container {
  background-color: #F9F9F9;
  border-top: 5px solid #12426a;
  padding: 20px;
  width: 765px;
  text-align: center;
}

.diffcard-qa-sub-container {
  margin: 20px;
}

.diffcard-question-main-container {
  margin-bottom: 10px;
}

.diffcard-question-container-active {
  background-color: #12426a;
  padding: 15px;
  text-align: left;
  color: #FFF;
  font-family: "Georgia",Times,serif;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.diffcard-question-container {
  background-color: #000;
  padding: 15px;
  text-align: left;
  color: #FFF;
  font-family: "Georgia",Times,serif;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.diffcard-question-container-active:hover  {
  background-color: #12426a;
  cursor: pointer;
}

.diffcard-question-container:hover  {
  background-color: #12426a;
  cursor: pointer;
}

.diffcard-question-sub-container {
  color: #FFF;
  font-family: "Georgia",Times,serif;
  font-size: 18px;
  display: flex;
}

.diffcard-text-content-title {
  font-size: 30px;
  line-height: 50px;
  padding-bottom: 10px;
  font-family: "Georgia",Times,serif;
  font-weight: lighter;
  margin-bottom: 0;
}

.diffcard-subtitle-container {
  font-style: italic;
  font-weight: bold;
}

.diffcard-answer-container {
  padding: 20px;
  text-align: left;
  font-size: 18px;
  color: #3d3d3d;
}

.diffcard-title {
  font-size: 3.4375em;
}

.diffcard-subtitle {
  font-size: 1.625em;
}

.diffcard-container {
  width: 100%;
  display: flex;
  padding: 70px 20px;
  max-width: 1200px;
  margin: auto;
}

.diffcard-container-hide {
  display: none;
}

.diffcard-img {
  width: 50%;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.diffcard-text-container {
  width: 100%;
  justify-content: center;
  display: flex;
}

.diffcard-text {
  width: 50%;
}

.diffcard-text-content {
  padding: 35px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 100%;
  color: #FFF;
}

.diffcard-text-content-subtext {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;
}

.diffcard-action-content-subtext {
  font-size: 18px;
  line-height: 28px;
  margin: 20px;
  margin-bottom: 0px;
}

.error-message-container {
  text-align: center;
  margin-top: 10px;
}

.error-message {
  text-align: center;
  font-size: 18px;
  color: red !important;
}

.contact-form-main-container {
  display: flex;
  justify-content: center;
  padding: 70px 20px;
}

.contact-form-unpad {
  padding: 0px 20px;
}

.contact-form-container {
  width: 625px;
}

.contact-form-input {
  width: 100%;
  border: 0px solid !important;
  border-bottom: 2px solid #12426a !important;
  border-radius: 1px !important;
  outline: none !important;
  font-size: 18px !important;
  padding: 6px !important;
  margin: 20px 0px !important;
}

.contact-form-input-radio-container {
  margin: 10px 0px !important;
  font-size: 18px !important;
  color: #495057 !important;
}

.contact-form-input-radio {
  display: flex !important;
  align-items: start !important;
  padding-bottom: 5px;
}

.form-check-input {
  margin-top: 0.45rem !important;
}

.form-check-label {
  color: #495057;
  font-weight: bold;
}

.diffcard-submission-form-sub-container p {
  color: #495057;
  line-height: 24px;
}

.notice {
  font-size: 24px;
  border: 2px dashed #495057;
  padding: 15px 25px;
  background: #f5f5f5;
}

.contact-form-btn {
  background-clip: border-box;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 3px solid #12426a;
  padding: 10px 40px;
  background-color: #12426a;
  color: #FFF;
}

.contact-form-upload-btn {
  padding: 5px 30px;
  font-size: 16px;
}

.contact-form-file-container {
  background: rgba(0,0,0,.02);
  border: 1px dashed #ccc;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 24px;
  text-align: center;
}

input[type="file"] {
  display: none;
}

.contact-form-files-container {
  margin: 20px 0;
}

.contact-form-files-container-support {
  margin: 0;
  margin-bottom: 25px;
}

.contact-form-files-container-text {
  font-style: italic;
  color: #656565;
}

.contact-form-file-name {
  font-style: italic;
  color: #656565;
}

.contact-form-file-names {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d5d5d5;
  padding: 5px 10px 5px 10px;
  color: #495057;
}

.contact-form-file-action {
  color: red;
}

.contact-form-file-action:hover {
  cursor: pointer;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  background-clip: border-box;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 3px solid #12426a;
  padding: 5px 10px;
  background-color: #12426a;
  color: #FFF;
}

.custom-file-upload:hover {
  background-color: #FFF;
  color: #12426a;
}

.contact-form-file {
  text-align: center;
}

.contact-form-btn:hover {
  background-color: #FFF;
  cursor: pointer;
  color: #12426a;
}

.contact-form-input:focus {
  border-bottom: 2px solid #0693e3 !important;
  outline: none !important;
}

::placeholder {
  color: #878787;
}

.diffcard-contact-container {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 70px 20px;
}

.diffcard-contact-sub-container {
  border: 3px solid #12426a;
  background-clip: border-box;
  margin: 10px;
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.diffcard-contact-content {
  margin: 20px;
  width: 100%;
}

.card-sub-container {
  position: relative !important;
}

.card-sub-text-container {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
}

.testimonial-container {
  padding: 50px;
  padding-right: 10%;
  padding-left: 10%;
}

.testimonial-title {
  font-size: 1.625em;
  padding-bottom: 20px;
  color: #fed440;
  font-family: 'Source Code Pro';
}

.testimonial-text {
  font-size: 3.4375em;
  color: #fff;
}

.testimonial-subtext {
  font-size: 1.625em;
  padding-top: 20px;
  color: #fff;
}

.testimonial-main {
  background-color: #000;
  align-items: center;
  padding: 35px;
}

.custom-divider {
  padding: 50px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: 8%;
  margin-right: 8%;
}

.custom-divider2 {
  padding-top: 1px;
  padding-bottom: 10px;
  margin-right: 25px;
}

.request-demo-title {
  font-size: 3.4375em;
}

.request-demo-subtitle {
  font-size: 1.3125em;
}

.request-demo-title-container {
  padding: 75px;
}

.contact-us-container {
  padding: 75px;
  padding-top: 10px
}

.contact-us-title-container {
  text-align: center;
  /* padding: 75px; */
}

.contact-us-title {
  font-size: 3.4375em;
}

.contact-us-card-container {
  display: inline-flex;
  width: 100%;
}

.contact-us-card-right {
  width: 50%;
  padding-left: 5%;
}

.contact-us-card-left {
  width: 50%;
  padding-right: 5%;
}

.contact-us-card-content-container {
  background-color: #ebebeb;
  height: 311px;
}

.contact-us-card-content {
  padding: 48px;
}

.contact-us-card-content-title {
  font-size: 2.125em;
  margin-bottom: 10px;
}

.inquiry-container {
  background-color: #fed440;
  padding: 50px;
}

.form-icon {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}
.page-header-btn-text {
  font-size: 32px;
}

.modal-container {
  padding: 42px;
}

.footer {
  display: flex;
  justify-content: space-evenly;
  padding: 35px
}

.footer-logo {
  padding: 50px;
  padding-left: 10.5%;
  padding-bottom: 35px;
}

@media only screen and (max-width: 1380px) {
  .diffcard-text-content-title {
    font-size: 1.625em;
  }

  .diffcard-text-content-subtext {
    font-size: 1.3125em;
  }

  /* .diffcard-element {
    padding-right: unset;
    padding-left: unset;
  } */

  .page-header-text {
    font-size: 48px;
    line-height: 1.4;
  }

  .page-header-sub-text {
    font-size: 28px;
    line-height: 1.4;
  }

  .diffcard-statement-sub-container {
    width: 75%;
  }
}

@media only screen and (max-width: 1350px) {
  .home-img-text {
    font-size: 55px;
  }

  .home-img-text-sub {
    font-size: 26px;
  }

  .diffcard-text-content-subtext {
    font-size: 1em;
  }

  /* .diffcard-element {
    padding-right: unset;
    padding-left: unset;
  } */
}

@media only screen and (max-width: 1250px) {
  .diffcard-statement-sub-container {
    width: 85%;
  }
}

@media only screen and (max-width: 1125px) {
  .logo {
    width: 80px;
    height: 80px;
    margin-left: 1px;
  }

  .logo-text {
    font-size: 24px;
    margin: 0;
  }

  .homeimg {
    width: 100%;
    height: 414px;
    object-fit: cover;
  }

  .page-header-img {
    height: 400px;
  }

  .page-header-text-container {
    width: 100%;
    text-align: center;
  }

  .eb-link-container {
    margin-left: 25px;
  }

  .eb-link {
    font-size: 18px;
  }

  .text-block {
    text-align: center;
    position: unset;
    background-color: #fff;
    color: #000;
    width: 100%;
    height: unset;
  }

  .two-color-card-subtitle {
    text-align: center;
    font-size: 2.125em;
  }

  .two-color-card-subtext {
    font-size: 1.3125em;
  }

  .diffcard-text-content {
    padding: 0px;
    height: 100%;
  }

  .diffcard-text-content-subtext {
    font-size: 1.3125em;
  }

  .diffcard-text-content-title {
    line-height: 1.4;
  }

  .diffcard-statement-sub-container {
    width: 98%;
  }

  .diffcard-container {
    /* width: 100%;
    display: inline-grid;
    padding-top: 20px;
    padding-bottom: 20px; */
    display: flex;
    width: 100%;
  }

  .diffcard-text-content-title {
    font-size: 18px;
  }

  .diffcard-text-content-subtext {
    font-size: 16px;
  }

  .diffcard-element {
    padding-right: 5px;
    padding-left: 5px;
    margin: 20px 0;
  }

  .diffcard-img {
    width: 100%;
    padding: 15px;
  }
  
  .diffcard-text {
    width: 100%;
    order: 2;
    padding: 15px;
  }

  .testimonial-text {
    font-size: 2.125em;
    color: #fff;
  }

  .about-us-title-container {
    padding: 26px;
    padding-top: 42px;
  }

  .about-us-table-container {
    padding-left: 1px;
    padding-right: 1px;
  }

  .two-color-card-sub-container {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }

  .table-title {
    font-size: 2.125em;
  }

  .table-content-container {
    padding-bottom: 1px;
  }

  .table-content {
    padding: 50px;
  }

  .table-subtext {
    font-size: 1.3125em;
  }

  .verticalAlignItem {
    margin-top: 0;
    padding: 25px;
  }

  .contact-us-card-content {
    padding: 16px;
  }

  .home-img-text {
    font-size: 55px;
  }
  
  .home-img-text-sub {
    font-size: 26px;
    line-height: normal;
  }
  
  .basic-page-container {
    padding-left: 75px;
    padding-right: 75px;
  }

  .paragraph-title {
    font-size: 2.125em;
  }

  .paragraph-subtext {
    font-size: 1.3125em;
  }

  .request-demo-title {
    font-size: 2.125em;
  }

  .request-demo-subtitle {
    font-size: 1.3125em;
  }

  .ebLink {
    cursor: pointer;
  }

  .ebBtnContainer {
    padding-left: 0px;
  }

  .page-header-btn-text {
    font-size: 24px;
  }

  .page-header-text {
    width: 100%;
  }

  .page-header-sub-text {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .eb-link-container {
    margin: 10px 0;
  }
}

@media only screen and (max-width: 960px) {
  .submission-btn-container {
    display: block;
  }

  .diffcard-action-container {
    width: unset;
  }

  .card-sub-text-container {
    position: absolute !important;
    bottom: 0 !important;
    width: 100% !important;
  }

  .diffcard-statement-info {
    display: block;
  }

  .diffcard-brand-container {
    text-align: center;
    margin-bottom: 20px;
  }

  .diffcard-icon-main-container {
    display: grid;
    justify-content: center;
  }

  .diffcard-icon-container {
    padding-bottom: 25px;
  }

  .page-header-text-container {
    width: 100%;
  }

  .page-header-text {
    text-align: center;
  }

  .page-header-sub-text {
    text-align: center;
  }

  .page-header-btn-container {
    text-align: center;
  }

  .page-header-img {
    height: 475px;
  }

  .field-box-container {
    width: unset;
  }

  .ebBtn:hover {
    color: #fed440;
  }

  .sub-nav-item {
    color: #FFF;
    padding: 10px;
    text-decoration-line: underline;
  }

  .sub-nav-item:hover {
    color: #fed440;
  }

  .navContainer {
    padding-top: 15px;
  }

  .homeimg {
    width: 100%;
    height: 414px;
    object-fit: cover;
  }

  .ebLinkContainer {
    padding-left: inherit;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ebBtnContainer {
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ebBtn {
    background-color: #000;
    color: #fff;
    border: 0px;
    height: inherit;
    padding: inherit;
    text-decoration-line: underline;
  }

  .ebLink {
    padding: inherit;
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .text-block {
    position: unset;
    background-color: #fff;
    color: #000;
    width: 100%;
    height: unset;
  }

  .about-us-title-container {
    padding: 26px;
    padding-top: 42px;
  }

  .about-us-table {
    padding: 26px;
  }

  .verticalAlignItem {
    display: inline-block;
    margin-top: 0px;
  }

  .home-img-text {
    font-size: 2.125em;
  }
  
  .home-img-text-sub {
    font-size: 1.3125em;
  }

  .home-img-text-container {
    padding: 16px;
    padding-top: 42px;
    padding-bottom: 42px;
  }

  .diffcard-text-content-title {
    font-size: 1.625em;
  }

  .diffcard-title {
    font-size: 2.125em;
    line-height: normal;
    padding-bottom: 20px;
  }
  
  .diffcard-subtitle {
    font-size: 1em;
    line-height: normal;
  }

  .diffcard-container {
    width: 100%;
    display: inline-grid;
    padding: 0px;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
  }

  .diffcard-img {
    width: 100%;
  }
  
  .diffcard-text {
    width: 100%;
    order: 2;
  }

  .diffcard-text-content {
    padding: 25px;
    height: unset;
  }

  .testimonial-container {
    padding: 50px;
    padding-right: 2%;
    padding-left: 2%;
  }

  .testimonial-title {
    font-size: 1em;
    padding-bottom: 20px;
    color: #fed440;
    font-family: 'Source Code Pro';
  }

  .testimonial-text {
    font-size: 1.625em;
    color: #fff;
  }

  .testimonial-subtext {
    font-size: 1em;
    padding-top: 20px;
    color: #fff;
  }

  .testimonial-main {
    background-color: #000;
    align-items: center;
    padding: 35px;
    padding-bottom: 5px;
  }

  .custom-divider {
    padding: 50px;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-left: 0%;
    margin-right:0%;
  }

  .custom-divider2 {
    padding-top: 1px;
    padding-bottom: 10px;
    margin-right: 1px;
  }

  .mobile-hidden {
    display: none;
  }

  .mobile-show {
    display: block;
  }

  .footer {
    display: inline-block;
    padding-left: 15%;
    padding-top: 20px;
  }

  .footer-logo {
    padding: 50px;
    padding-left: 12.5%;
    padding-bottom: 35px;
  }

  .modal-text {
    font-size: 18px;
  }

  .twocolordiv  {
    background: white;
    padding: 0px;
  }

  .twocolordiv:before {
    background: #fff;
  }

  .two-color-card-container {
    padding-left: 0%;
    padding-right: 0%;
  }

  .two-color-card-text {
    font-size: 2.125em;
  }

  .two-color-card-text-container {
    padding: 16px;
    padding-top: 42px;
  }

  .two-color-card-sub-container {
    padding: 35px;
  }

  .two-color-card-subtitle {
    font-size: 1.625em;
  }
  
  .two-color-card-subtext {
    font-size: 1.3125em;
    padding: 1px;
    padding-top: 10px;
  }

  .table-container {
    padding: 16px;
    padding-top: 42px;
  }

  .table-title {
    font-size: 1.625em;
  }

  .table-sub-container {
    padding: 1px;
  }
  
  .table-subtext {
    font-size: 1.3125em;
    padding: 1px;
  }

  .table-content {
    padding: 1px;
  }

  .table-content-container {
    padding-bottom: 1px;
  }

  .table-column {
    padding: 25px;
    padding-bottom: 1px;
  }

  .table-column-container {
    display: inline-block;
  }

  .paragraph-container {
    padding: 16px;
    padding-top: 42px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .paragraph-header-container {
    padding-left: 26px;
    padding-right: 26px;
  }

  .paragraph-title-main {
    font-size: 2.125em;
  }

  .paragraph-title {
    font-size: 1.625em;
  }

  .paragraph-sub-container {
    padding: 1px;
  }

  .paragraph-subtext {
    font-size: 1.3125em;
    padding: 1px;
  }

  .horizontal-diff-card-container {
    padding: 16px;
  }

  .horizontal-diff-container {
    display: inline-block;
  }

  .horizontal-diff-sub-container {
    width: 100%;
    padding-top: 20px;
  }

  .horizontal-diff-img {
    width: 150px;
    height: 150px;
  }

  .request-demo-title {
    font-size: 1.625em;
  }

  .request-demo-subtitle {
    font-size: 1em;
  }

  .request-demo-title-container {
    padding: 16px;
  }

  .contact-us-container {
    padding: 26px;
  }

  .contact-us-title {
    font-size: 2.125em;
  }

  .contact-us-title-container {
    text-align: center;
    padding-top: 42px;
  }

  .contact-us-card-container {
    display: inline-block;
  }

  .contact-us-card-right {
    width: 100%;
    padding-left: 0%;
    padding-bottom: 42px;
  }

  .contact-us-card-left {
    width: 100%;
    padding-right: 0%;
    padding-bottom: 42px;
  }

  .contact-us-card-content-container {
    background-color: #ebebeb;
    height: unset;
  }

  .contact-us-card-content {
    padding: 16px;
  }

  .contact-us-card-content-title {
    font-size: 1.625em;
  }

  .inquiry-container {
    background-color: #fed440;
    padding: 26px;
  }

  .form-container {
    width: 100%;
  }

  .field-container {
    display: inline-block;
    width: 100%;
  }

  .field-label {
    font-size: 1em;
  }

  .basic-page-container {
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 42px;
  }

  .basic-page-title-main {
    font-size: 2.125em;
  }

  .basic-page-title-container {
    padding-top: 42px;
    padding-bottom: 10px;
  }

  .basic-page-content-block-title {
    font-size: 1.3125em;
  }

  .modal-container {
    padding: 16px;
  }

  .diffcard-submission-form-container {
    width: unset;
  }

  .diffcard-qa-container {
    width: 100%;
  }

  .page-header-btn-text {
    font-size: unset;
  }

  .error-message {
    color: red !important;
  }
}

@media only screen and (max-width: 720px) {
  .diffcard-text-container {
    padding: 0px 20px;
  }

  .diffcard-footer-link-main-container {
    padding: 20px 0;
  }

  .diffcard-footer-link-container {
    font-size: 16px;
    display: grid;
    justify-content: flex-start;
  }

  .diffcard-footer-link-sub-container {
    border-left: 0;
  }

  .diffcard-contact-container {
    display: grid;
  }

  .page-header-text {
    font-size: 40px;
  }

  .notice {
    font-size: 20px;
    border: 2px dashed #495057;
    padding: 15px 25px;
    background: #f5f5f5;
  }
}

@media only screen and (max-width: 600px) {
  .page-header-img {
    object-fit: cover;
    width: 100%;
    height: 450px;
  }

  .page-header-text-container {
    width: 100%;
  }

  .page-header-text {
    font-size: 36px;
    line-height: unset;
    font-family: "Georgia", Times, serif;
  }

  .page-header-sub-text {
    font-size: 20px;
    line-height: unset;
  }

  .verticalAlignItem {
    padding: 0;
  }

  .diffcard-text-content {
    padding: 0;
  }

  .diffcard-statement-content {
    font-size: 24px;
  }

  .diffcard-contact-container {
    display: block;
    padding: 20px 20px;
  }

  .diffcard-contact-sub-container {
    width: 100%;
    margin: 20px 0;
    padding: 5px;
  }
}

@media only screen and (max-width: 565px) {
  .page-header-text {
    font-size: 24px;
    line-height: unset;
    font-family: "Georgia", Times, serif;
  }

  .page-header-sub-text {
    font-size: 18px;
  }

  .page-header-text-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .diffcard-footer-ebLogo {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 385px) {
  .logo {
    width: 55px;
    height: 55px;
    margin-right: 10px;
  }

  .logo-text {
    font-size: 18px;
  }

  .diffcard-text {
    padding: 10px;
  }

  .diffcard-text-content-title {
    font-size: 18px;
  }

  .diffcard-text-content-subtext {
    font-size: 14px;
    line-height: 24px;
  }

  .page-header-btn {
    padding: 10px;
    font-size: 14px;
  }

  .page-header-text-sub-container {
    padding: 0;
  }

  .diffcard-qa-sub-container {
    margin: 10px;
  }

  .diffcard-icon-text {
    font-size: 16px;
  }

  .diffcard-answer-container {
    font-size: 16px;
  }

  .ebLogo {
    height: 30px;
  }

  .ebLogo-text {
    font-size: 14px;
  }

  .notice {
    font-size: 18px;
    border: 2px dashed #495057;
    padding: 15px 25px;
    background: #f5f5f5;
  }
}

@media only screen and (max-width: 260px) {
  .page-header-img {
    height: 500px;
  }

  .notice {
    font-size: 16px;
    border: 2px dashed #495057;
    padding: 15px 25px;
    background: #f5f5f5;
  }
}